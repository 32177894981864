import AOS from "aos";
import throttle from 'lodash.throttle';

function init()
{
    let menu = document.querySelector('#menu'), open = true,
    menuBtn = document.querySelector('#menu-button'),
    pllx = document.querySelector('.parallax')
    , scrollFunc = function(e) {
       if(!open && (e.target.scrollTop > 213) && (new RegExp('open').test(menuBtn.className))) {
            open = true;
            menuBtn.click()
            pllx.removeEventListener('scroll', scrollFunc)
        }
    }
    menuBtn.addEventListener('click', function(e)
        {
          e.preventDefault()
          e.stopPropagation()
          if(!open)
          {
             menu.classList.add('open')
             menuBtn.classList.add('open')
          } else {
             menu.classList.remove('open')
             menuBtn.classList.remove('open')
          }

          open = !open
        })
    //pllx.addEventListener('scroll', scrollFunc )
    AOS.init()
    document.body.querySelector('.parallax').addEventListener('scroll', throttle(() => {
        AOS.refresh()
    }));
}

document.addEventListener('DOMContentLoaded', init, 0);
